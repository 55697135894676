import { Ticket } from "./tickets.model";

export class TicketsService {
    static filterOpenTickets(tickets: Ticket[]): Ticket[] {
        return tickets.filter(t => this.isOpen(t));
    }

    static filterTicketsCreatedBy(userId: string, tickets: Ticket[]): Ticket[] {
        return tickets.filter(t => t.createdBy === userId);
    }

    static filterTicketsAssignedTo(userId: string, tickets: Ticket[]): Ticket[] {
        return tickets.filter(t => t.assignedTo === userId);
    }

    static isOpen(ticket: Ticket): boolean {
        return ticket.state !== 'closed' && ticket.state !== 'rejected';
    }
}