import { Dialog, DialogSurface, DialogTrigger, ToolbarButton } from "@fluentui/react-components";
import { FunctionComponent, PropsWithChildren } from "react";
import {
    bundleIcon,
    EditRegular,
    EditFilled
} from "@fluentui/react-icons";
import EditAssetDialogBody from "./EditAssetDialogBody";
import { AssetDialogProps } from "./asset-dialogs.model";
import MultiEditAssetDialogBody from "./MultiEditAssetDialogBody";

const Edit = bundleIcon(EditFilled, EditRegular);

export interface EditAssetToolbarDialogProps extends AssetDialogProps {
    disabled?: boolean;
}

const EditAssetToolbarDialog: FunctionComponent<PropsWithChildren<EditAssetToolbarDialogProps>> = ({
    children,
    disabled = false,
    assets
}) => {
    return (
        <Dialog>
            <DialogTrigger>
                <ToolbarButton disabled={disabled} aria-label="Edit" appearance="subtle" icon={<Edit />}>{children}</ToolbarButton>
            </DialogTrigger>
            <DialogSurface>
                {assets.length === 1 && <EditAssetDialogBody asset={assets[0]} />}
                {assets.length > 1 && <MultiEditAssetDialogBody assets={assets} />}
            </DialogSurface>
        </Dialog>
    );
}

export default EditAssetToolbarDialog;