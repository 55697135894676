import { Subtitle2 } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import CreateAssetDialog from "../assets/asset-dialogs/CreateAssetDialog";
import { useAppSelector } from "../../state/hooks";
import { getHasEditRole } from "../user-roles/user-roles.slice";
import CreateTicketDialog from "../tickets/ticket-dialogs/CreateTicketDialog";

const NoAssetsHint: FunctionComponent = () => {
    const { t } = useTranslation();
    const isManager = useAppSelector(getHasEditRole);

    return (
        <div className="flex flex-col justify-center items-center m-8 gap-8">
            <Subtitle2>{t('assetTables.noAssets')}</Subtitle2>
            <Subtitle2>{isManager ? t('assetTables.noTickets') : t('assetTables.noMyTickets')}</Subtitle2>
            {isManager && <CreateAssetDialog />}
            {!isManager && <CreateTicketDialog />}
        </div>
    );
}

export default NoAssetsHint;