import { FunctionComponent, useState } from "react";
import { Combobox, ComboboxProps, Option, useId } from "@fluentui/react-components";

export interface DynamicComboboxProps {
    defaultValue?: string | null;
    allowFreeform?: boolean;
    createValueHint?: string;
    values: string[];
    onSelect?: ((value: string | null) => void) | null;
}

const DynamicCombobox: FunctionComponent<DynamicComboboxProps> = ({
    defaultValue = null,
    allowFreeform = false,
    createValueHint = null,
    values,
    onSelect = null
}) => {
    const locationInputId = useId('locationInputId');
    const [matchingOptions, setMatchingOptions] = useState([...values]);
    const [customValue, setCustomValue] = useState<string | null>();

    const onChange: ComboboxProps["onChange"] = (event) => {
        const value = event.target.value.trim();
        const matches = values.filter(
            (option) => option.toLowerCase().indexOf(value.toLowerCase()) === 0
        );

        setMatchingOptions(matches);

        if (value.length && matches.length < 1 && allowFreeform) {
            setCustomValue(value);
        } else {
            setCustomValue(null);
        }
    };

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
        const matchingOption = data.optionText && values.includes(data.optionText);
        if (matchingOption) {
            setCustomValue(null);
        } else {
            setCustomValue(data.optionText);
        }

        if (onSelect) {
            onSelect(!data.optionText ? null : data.optionText);
        }
    };

    return (
        <Combobox id={locationInputId} className="w-full mt-2" clearable freeform={allowFreeform}
            onChange={onChange} onOptionSelect={onOptionSelect} defaultValue={defaultValue !== null ? defaultValue : undefined}>
            {customValue ? (
                <Option key={customValue} text={customValue}>
                    {createValueHint === null ? 'Create' : createValueHint} "{customValue}"
                </Option>
            ) : null}
            {matchingOptions.map((option) => (
                <Option key={option}>{option}</Option>
            ))}
        </Combobox>
    );
}

export default DynamicCombobox;