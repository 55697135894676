import {
    DataGrid, DataGridBody, DataGridCell,
    DataGridHeader, DataGridHeaderCell,
    DataGridRow, TableCellLayout, TableColumnDefinition, createTableColumn, Toolbar, SelectionItemId,
    Text
} from "@fluentui/react-components";
import { FunctionComponent, useState } from "react";
import AssetTableViewToolbar from "./AssetTableViewToolbar";
import { AssetTableViewProps } from "./asset-table.model";
import ViewAssetToolbarDialog from "../asset-dialogs/ViewAssetToolbarDialog";
import EditAssetToolbarDialog from "../asset-dialogs/EditAssetToolbarDialog";
import DeleteAssetsToolbarDialog from "../asset-dialogs/DeleteAssetsToolbarDialog";
import { Person } from "@microsoft/mgt-react";
import NoAssetsHint from "./NoAssetsHint";
import { useTranslation } from "react-i18next";
import { Asset, getFilteredAssets } from "../assets.slice";
import { useAppSelector } from "../../../state/hooks";
import { getHasEditRole } from "../../user-roles/user-roles.slice";

import {
    bundleIcon,
    Note16Regular,
    Note16Filled
} from "@fluentui/react-icons";

const Note = bundleIcon(Note16Filled, Note16Regular);

const AssetTableView: FunctionComponent<AssetTableViewProps> = ({
    types,
    locations,
    columns = [
        'name',
        'type',
        'location',
        'usedBy',
        'managedBy',
        'actions'
    ]
}) => {
    const { t } = useTranslation();
    const [selection, setSelection] = useState<Asset[] | null>([]);
    const isManager = useAppSelector(getHasEditRole);
    const filteredAssets: Asset[] = useAppSelector(getFilteredAssets);

    const updateSelection = (selectionSet: Set<SelectionItemId>) => {
        const selected: Asset[] = [];

        selectionSet.forEach((id) => {
            const assetIndex = filteredAssets.findIndex((a) => a.id === id);
            if (assetIndex !== -1) {
                selected.push(filteredAssets[assetIndex]);
            }
        });

        setSelection(selected);
    };

    const renderedColumns: TableColumnDefinition<Asset>[] = [];

    if (columns.includes('name')) {
        renderedColumns.push(createTableColumn<Asset>({
            columnId: 'name',
            compare: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            renderHeaderCell: () => {
                return t('assetTable.columns.name');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <div className="flex items-center">
                            {item.name}
                            {item.notes && <div className="ml-4"><Note /></div>}
                        </div>
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('type')) {
        renderedColumns.push(createTableColumn<Asset>({
            columnId: 'type',
            compare: (a, b) => {
                return a.type.localeCompare(b.type);
            },
            renderHeaderCell: () => {
                return t('assetTable.columns.type');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.type}
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('location')) {
        renderedColumns.push(createTableColumn<Asset>({
            columnId: 'location',
            compare: (a, b) => {
                return a.location.localeCompare(b.location);
            },
            renderHeaderCell: () => {
                return t('assetTable.columns.location');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.location ? item.location : t('assetTable.noValue')}
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('usedBy')) {
        renderedColumns.push(createTableColumn<Asset>({
            columnId: 'usedBy',
            compare: (a, b) => {
                return a.usedBy.localeCompare(b.usedBy);
            },
            renderHeaderCell: () => {
                return t('assetTable.columns.usedBy');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.usedBy ? <Person view="oneline" personCardInteraction="hover" userId={item.usedBy} /> : <Text>{t('assetTable.noValue')}</Text>}
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('managedBy')) {
        renderedColumns.push(createTableColumn<Asset>({
            columnId: 'managedBy',
            compare: (a, b) => {
                return a.managedBy.localeCompare(b.managedBy);
            },
            renderHeaderCell: () => {
                return t('assetTable.columns.managedBy');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.managedBy ? <Person view="oneline" personCardInteraction="hover" userId={item.managedBy} /> : <Text>{t('assetTable.noValue')}</Text>}
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('actions')) {
        renderedColumns.push(createTableColumn<Asset>({
            columnId: 'actions',
            compare: (a, b) => {
                return a.name.localeCompare(b.name);
            },
            renderHeaderCell: () => {
                return t('assetTable.columns.actions');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <Toolbar>
                            <ViewAssetToolbarDialog assets={[item]} />
                            {isManager && <EditAssetToolbarDialog assets={[item]} />}
                            {isManager && <DeleteAssetsToolbarDialog assets={[item]} />}
                        </Toolbar>
                    </TableCellLayout>
                );
            }
        }));
    }

    return (
        <div>
            <AssetTableViewToolbar selection={selection}
                types={types}
                locations={locations}
            />

            <DataGrid items={filteredAssets}
                columns={renderedColumns}
                sortable
                selectionMode="multiselect"
                subtleSelection
                getRowId={(item) => item.id}
                focusMode="cell"
                onSelectionChange={(_e, d) => updateSelection(d.selectedItems)}>
                <DataGridHeader>
                    <DataGridRow selectionCell={{
                        checkboxIndicator: { 'aria-label': 'Select all rows' }
                    }}>
                        {({ renderHeaderCell }) => (<DataGridHeaderCell>
                            {renderHeaderCell()}
                        </DataGridHeaderCell>)}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<Asset>>
                    {({ item, rowId }) => (
                        <DataGridRow<Asset> key={rowId} selectionCell={{
                            checkboxIndicator: { 'aria-label': 'Select row' }
                        }}>
                            {({ renderCell }) => (<DataGridCell>
                                {renderCell(item)}
                            </DataGridCell>)}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>

            {filteredAssets.length === 0 && <NoAssetsHint />}
        </div>
    );
};

export default AssetTableView;