import { FunctionComponent, PropsWithChildren } from "react";

const WidgetContainer: FunctionComponent<PropsWithChildren> = (props) => {
    return (
        <div className="flex justify-start gap-8">
            {props.children}
        </div>
    );
};

export default WidgetContainer;