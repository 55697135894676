import { Button, Caption1, DialogActions, DialogBody, DialogContent, DialogTitle, DialogTrigger, Field, InfoLabel, Input, InputProps, Textarea, TextareaProps } from "@fluentui/react-components";
import { IDynamicPerson, PeoplePicker } from "@microsoft/mgt-react";
import { FunctionComponent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../state/hooks";
import { TeamsFxContext } from "../../../components/Context";
import { TicketCreatePayload, postTicket } from "../tickets.slice";

type ValidationState = "error" | "none" | "warning" | "success" | undefined;

interface FormData {
    titleInput: string;
    titleValidationState: ValidationState;
    descriptionInput: string;
    descriptionValidationState: ValidationState;
    assignedToInput: string | undefined;
    assignedToValidationState: ValidationState;
}

const CreateTicketDialogBody: FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);

    const defaultFormData: FormData = {
        titleInput: '',
        titleValidationState: 'none',
        descriptionInput: '',
        descriptionValidationState: 'none',
        assignedToInput: '',
        assignedToValidationState: 'none'
    };

    const [formData, setFormData] = useState<FormData>(defaultFormData);

    const onTitleChange: InputProps["onChange"] = (ev, data) => {
        setFormData({
            ...formData,
            titleInput: data.value,
            titleValidationState: !data.value ? 'error' : 'success'
        });
    };


    const onDescriptionChange: TextareaProps["onChange"] = (ev, data) => {
        setFormData({
            ...formData,
            descriptionInput: data.value,
            descriptionValidationState: !data.value ? 'error' : 'success'
        });
    };

    const onAssignedToChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }
        setFormData({
            ...formData,
            assignedToInput: id,
        });
    };

    const validate = (): boolean => {
        let anyInvalid = false;

        if (!formData.titleInput) {
            anyInvalid = true;
        }

        if (!formData.descriptionInput) {
            anyInvalid = true;
        }

        if (!formData.assignedToInput) {
            anyInvalid = true;
        }

        return !anyInvalid;
    };

    const createTicket = async () => {
        const newTicket: TicketCreatePayload = {
            title: formData.titleInput,
            description: formData.descriptionInput,
            assignedTo: formData.assignedToInput!
        };

        dispatch(postTicket({ newTicket, teamsUserCredential: teamsUserCredential! }))
    };

    const canSave = validate();

    return (
        <DialogBody>
            <DialogTitle>{t('ticketDialog.createTicket')}</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4 py-4">
                    <Caption1>{t('ticketDialog.createTicketInstruction')}</Caption1>
                    <Field required validationState={formData.titleValidationState} label={
                        <InfoLabel className="inline-flex" info={t('ticketDialog.titleHint')}>
                            {t('ticketDialog.title')}
                        </InfoLabel>
                    }>
                        <Input defaultValue={formData.titleInput} onChange={onTitleChange} />
                    </Field>

                    <Field required label={t('ticketDialog.description')} hint={t('ticketDialog.descriptionHint')}>
                        <Textarea defaultValue={formData.descriptionInput} onChange={onDescriptionChange} />
                    </Field>

                    <Field required validationState={formData.assignedToValidationState} label={
                        <InfoLabel className="inline-flex" info={t('ticketDialog.assignedToHint')}>
                            {t('ticketDialog.assignedTo')}
                        </InfoLabel>
                    }>
                        <PeoplePicker
                            defaultSelectedUserIds={formData.assignedToInput ? [formData.assignedToInput] : []}
                            userType='user'
                            selectionMode="single"
                            selectionChanged={onAssignedToChange} />
                    </Field>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">{t('general.close')}</Button>
                </DialogTrigger>
                <DialogTrigger disableButtonEnhancement>
                    <Button disabled={!canSave} appearance="primary" onClick={createTicket}>{t('general.send')}</Button>
                </DialogTrigger>
            </DialogActions>
        </DialogBody>
    );
}

export default CreateTicketDialogBody;