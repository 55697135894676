import { FunctionComponent } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    Button,
} from "@fluentui/react-components";

import {
    bundleIcon,
    AddCircleRegular,
    AddCircleFilled
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import CreateTicketDialogBody from "./CreateTicketDialogBody";

const AddCircle = bundleIcon(AddCircleFilled, AddCircleRegular);

const CreateTicketDialog: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    appearance="primary"
                    icon={<AddCircle />}>{t('ticketDialog.createTicket')}</Button>
            </DialogTrigger>
            <DialogSurface>
                <CreateTicketDialogBody />
            </DialogSurface>
        </Dialog>
    );
};

export default CreateTicketDialog;