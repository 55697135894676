import { Subtitle2 } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const NoTicketsHint: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className="flex justify-center m-8">
            <Subtitle2>{t('ticketTable.noResults')}</Subtitle2>
        </div>
    );
}

export default NoTicketsHint;