import { Button, DialogActions, DialogBody, DialogContent, DialogTitle, DialogTrigger, Persona, Subtitle2, Text } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import { AssetDialogProps } from "./asset-dialogs.model";
import { Person } from "@microsoft/mgt-react";
import { useTranslation } from "react-i18next";

const ViewAssetDialogBody: FunctionComponent<AssetDialogProps> = ({
    assets
}) => {
    const { t } = useTranslation();
    const asset = assets[0];

    return (
        <DialogBody>
            <DialogTitle>{asset.name}</DialogTitle>
            <DialogContent>
                <div className="my-8 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('assetDialog.type')}</Subtitle2>
                        <Text>{asset.type}</Text>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('assetDialog.location')}</Subtitle2>
                        <Text>{asset.location}</Text>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('assetDialog.usedBy')}</Subtitle2>
                        <Person view="oneline" personCardInteraction="hover" userId={asset.usedBy} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('assetDialog.managedBy')}</Subtitle2>
                        <Person view="oneline" personCardInteraction="hover" userId={asset.managedBy} />
                    </div>
                    {asset.notes && <div className="flex flex-col gap-2">
                        <Subtitle2>{t('assetDialog.notes')}</Subtitle2>
                        <Text>{asset.notes}</Text>
                    </div>}
                </div>
            </DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">{t('general.close')}</Button>
                </DialogTrigger>
            </DialogActions>
        </DialogBody>
    );
}

export default ViewAssetDialogBody;