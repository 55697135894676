import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: require('./locales/en.json')
  },
  // de: {
  //   translation: require('./locales/de.json')
  // }
};

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;