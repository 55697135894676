import { FunctionComponent, useContext, useEffect } from "react";
import { Title3 } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import NoTicketsHint from "./NoTicketsHint";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { TeamsFxContext } from "../../components/Context";
import { getOpenTickets, loadTickets } from "../tickets/tickets.slice";
import { Ticket } from "../tickets/tickets.model";
import { getCurrentUserId, getHasEditRole } from "../user-roles/user-roles.slice";
import TicketTableView from "../tickets/ticket-table/TicketTableView";
import { TicketsService } from "../tickets/tickets.service";

const TicketTables: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);
    useEffect(() => {
        if (teamsUserCredential) {
            dispatch(loadTickets(teamsUserCredential!));
        }
    }, [teamsUserCredential]);

    const { t } = useTranslation();
    const openTickets: Ticket[] = useAppSelector(getOpenTickets);
    const isManager = useAppSelector(getHasEditRole);
    const currentUserId = useAppSelector(getCurrentUserId);
    const displayedTickets = isManager ? TicketsService.filterTicketsAssignedTo(currentUserId!, openTickets) : TicketsService.filterTicketsCreatedBy(currentUserId!, openTickets);

    return (
        <div className="pt-4">
            <Title3 className="pl-6">{isManager ? t('ticketTables.tickets') : t('ticketTables.myTickets')}</Title3>

            {displayedTickets && displayedTickets.length > 0 &&
                <div className="mt-4">
                    <TicketTableView columns={[
                        'title',
                        'createdBy',
                        'assignedTo',
                        'actions'
                    ]} />
                </div>
            }

            {(displayedTickets === null || displayedTickets.length === 0) && <NoTicketsHint />}
        </div>
    );
};

export default TicketTables;