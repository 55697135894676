import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { ApiService } from "../../services/api-service";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { AssetsService } from "./assets.service";

export interface AssetsState {
    isLoading: boolean;
    assets: Asset[];
    types: string[];
    locations: string[];
    filters: AssetTableFilterOptions;
}

export interface Asset {
    id: string;
    name: string;
    type: string;
    location: string;
    usedBy: string;
    managedBy: string;
    notes: string;
    createdOn: Date;
}

export interface AssetCreatePayload {
    name: string;
    type: string;
    location: string;
    usedBy: string | undefined;
    managedBy: string | undefined;
    notes: string;
}

export interface AssetTableFilterOptions {
    query?: string;
    type?: string;
    location?: string;
    usedBy?: string;
    managedBy?: string;
}

export const initialState: AssetsState = {
    isLoading: false,
    assets: [],
    types: [],
    locations: [],
    filters: {},
};

export const loadAssets = createAsyncThunk("assets/loadAssets",
    async (teamsUserCredential: TeamsUserCredential) => {
        const assets = await ApiService.getRequest(teamsUserCredential, "assets");
        return assets;
    }
);
export const postAsset = createAsyncThunk("assets/postAsset",
    async (data: { newAsset: AssetCreatePayload, teamsUserCredential: TeamsUserCredential }) => {
        const asset = await ApiService.postRequest(data.teamsUserCredential, "assets", data.newAsset) as Asset;
        return asset;
    }
);

export const putAsset = createAsyncThunk("assets/putAsset",
    async (data: { asset: Asset, teamsUserCredential: TeamsUserCredential }) => {
        const asset = await ApiService.putRequest(data.teamsUserCredential, "assets", data.asset) as Asset;
        return asset;
    }
);

export const deleteAssets = createAsyncThunk("assets/deleteAsset",
    async (data: { assets: Asset[], teamsUserCredential: TeamsUserCredential }) => {
        const deletedAssets: string[] = [];
        try {
            for (const asset of data.assets) {
                const path = `assets/${asset.id}`;
                await ApiService.deleteRequest(data.teamsUserCredential, path);
                deletedAssets.push(asset.id);
            }
        } catch (error) {
            console.error(error);
        }
        return deletedAssets;
    }
);

const assetsSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadAssets.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(loadAssets.fulfilled, (state, action) => {
            state.isLoading = false;
            state.assets = action.payload;
            state.types = AssetsService.getTypes(state.assets);
            state.locations = AssetsService.getLocations(state.assets);
        });
        builder.addCase(loadAssets.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(postAsset.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postAsset.fulfilled, (state, action) => {
            state.isLoading = false;
            state.assets.push(action.payload);
        });
        builder.addCase(postAsset.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(putAsset.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(putAsset.fulfilled, (state, action) => {
            state.isLoading = false;
            const index = state.assets.findIndex((asset) => asset.id === action.payload.id);
            state.assets[index] = action.payload;
        });
        builder.addCase(putAsset.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteAssets.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteAssets.fulfilled, (state, action) => {
            state.isLoading = false;
            state.assets = state.assets.filter((asset) => !action.payload.includes(asset.id));
        });
        builder.addCase(deleteAssets.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { setIsLoading, setFilters } = assetsSlice.actions;
export const getIsLoading = (state: RootState) => state.assets.isLoading;
export const getAssets = (state: RootState) => state.assets.assets;
export const getMyAssets = (state: RootState) => state.assets.assets;
export const getUnassignedAssets = (state: RootState) => state.assets.assets;
export const getFilteredAssets = createSelector(
    (state: RootState) => state.assets.assets,
    (state: RootState) => state.assets.filters,
    (assets, filters) => AssetsService.filterAssets(assets, filters)
);

export const getFilters = (state: RootState) => state.assets.filters;
export const getTypes = (state: RootState) => state.assets.types;
export const getLocations = (state: RootState) => state.assets.locations;

export default assetsSlice.reducer;