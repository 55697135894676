import { FunctionComponent, useContext, useEffect } from "react";
import { Title3 } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import NoAssetsHint from "./NoAssetsHint";
import { TeamsFxContext } from "../../components/Context";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { Asset, getAssets, getLocations, getTypes, loadAssets } from "../assets/assets.slice";
import { getHasEditRole } from "../user-roles/user-roles.slice";
import AssetTableView from "../assets/asset-table/AssetTableView";

const AssetTables: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);
    useEffect(() => {
        if (teamsUserCredential) {
            dispatch(loadAssets(teamsUserCredential!));
        }
    }, [teamsUserCredential]);

    const { t } = useTranslation();
    const myAssets: Asset[] = useAppSelector(getAssets);
    const types: string[] = useAppSelector(getTypes);
    const locations: string[] = useAppSelector(getLocations);
    const isManager = useAppSelector(getHasEditRole);

    return (
        <div className="pt-4">
            <Title3 className="pl-6">{isManager ? t('assetTables.assets') : t('assetTables.myAssets')}</Title3>

            {myAssets && myAssets.length > 0 &&
                <AssetTableView types={types} locations={locations} />
            }

            {(myAssets === null || myAssets.length === 0) && <NoAssetsHint />}
        </div>
    );
};

export default AssetTables;