import { Button, Caption1, DialogActions, DialogBody, DialogContent, DialogTitle, DialogTrigger, Field, Subtitle2, Text } from "@fluentui/react-components";
import { FunctionComponent, useContext, useState } from "react";
import { Person } from "@microsoft/mgt-react";
import { useTranslation } from "react-i18next";
import { TicketDialogProps } from "./ticket-dialogs.model";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { getHasEditRole } from "../../user-roles/user-roles.slice";

import {
    bundleIcon,
    DismissRegular,
    DismissFilled,
    CheckmarkRegular,
    CheckmarkFilled
} from "@fluentui/react-icons";
import DynamicCombobox from "../../../components/input/DynamicCombobox";
import { Asset, getAssets, putAsset } from "../../assets/assets.slice";
import { putTicket } from "../tickets.slice";
import { TeamsFxContext } from "../../../components/Context";
import { Ticket } from "../tickets.model";

const Check = bundleIcon(CheckmarkFilled, CheckmarkRegular);
const Dismiss = bundleIcon(DismissFilled, DismissRegular);

type ValidationState = "error" | "none" | "warning" | "success" | undefined;

interface FormData {
    assetInput: string;
    assetValidationState: ValidationState;
}

const ViewTicketDialogBody: FunctionComponent<TicketDialogProps> = ({
    tickets
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);
    const isManager = useAppSelector(getHasEditRole);
    const assignableAssets = useAppSelector(getAssets);
    const ticket = tickets[0];

    const defaultFormData: FormData = {
        assetInput: '',
        assetValidationState: 'none',
    };

    const [formData, setFormData] = useState<FormData>(defaultFormData);

    const onAssetChange = (value: string | null) => {
        setFormData({
            ...formData,
            assetInput: value ? value : '',
            assetValidationState: value ? 'success' : 'error'
        });
    };

    const validate = (): boolean => {
        let anyInvalid = false;

        if (!formData.assetInput) {
            anyInvalid = true;
        }

        return !anyInvalid;
    };

    const rejectTicket = () => {
        const updatedTicket: Ticket = {
            ...ticket,
            state: 'rejected'
        }

        dispatch(putTicket({ ticket: updatedTicket, teamsUserCredential: teamsUserCredential! }));
    };

    const completeTicket = () => {
        const updatedTicket: Ticket = {
            ...ticket,
            state: 'closed'
        }

        const asset = assignableAssets.find(a => a.id === formData.assetInput);
        if (asset) {
            const updatedAsset: Asset = {
                ...asset,
                usedBy: ticket.createdBy
            };

            dispatch(putAsset({ asset: updatedAsset, teamsUserCredential: teamsUserCredential! }))
        }

        dispatch(putTicket({ ticket: updatedTicket, teamsUserCredential: teamsUserCredential! }));
    };

    const canComplete = validate();

    return (
        <DialogBody>
            <DialogTitle>{ticket.title}</DialogTitle>
            <DialogContent>
                <div className="my-8 flex flex-col gap-4">
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('ticketDialog.description')}</Subtitle2>
                        <Text>{ticket.description}</Text>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('ticketDialog.createdBy')}</Subtitle2>
                        <Person view="oneline" personCardInteraction="hover" userId={ticket.createdBy} />
                    </div>
                    <div className="flex flex-col gap-2">
                        <Subtitle2>{t('ticketDialog.assignedTo')}</Subtitle2>
                        <Person view="oneline" personCardInteraction="hover" userId={ticket.assignedTo} />
                    </div>
                    <div>
                        <div className="flex flex-col gap-2">
                            <Subtitle2>{t('ticketDialog.assetToAssign')}</Subtitle2>
                            <Caption1>{t('ticketDialog.assetToAssignInstruction')}</Caption1>
                        </div>
                        <Field validationState={formData.assetValidationState}>
                            <DynamicCombobox defaultValue={formData.assetInput} values={assignableAssets.map((a) => a.name)} onSelect={onAssetChange} />
                        </Field>
                    </div>
                </div>
            </DialogContent>

            {!isManager &&
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">{t('general.close')}</Button>
                    </DialogTrigger>
                </DialogActions>
            }

            {isManager &&
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">{t('general.close')}</Button>
                    </DialogTrigger>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" icon={<Dismiss />} onClick={rejectTicket}>{t('ticketDialog.reject')}</Button>
                    </DialogTrigger>
                    <DialogTrigger disableButtonEnhancement>
                        <Button disabled={!canComplete} appearance="primary" icon={<Check />} onClick={completeTicket}>{t('ticketDialog.complete')}</Button>
                    </DialogTrigger>
                </DialogActions>
            }
        </DialogBody>
    );
}

export default ViewTicketDialogBody;