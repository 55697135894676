import { FunctionComponent } from "react";
import {
    Body1,
    Body1Strong,
    Card,
    CardHeader,
    InfoLabel,
    Link,
    Spinner,
    Subtitle2,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../state/hooks";
import { getAssets, getIsLoading, getMyAssets, getUnassignedAssets } from "../assets/assets.slice";
import { getHasEditRole } from "../user-roles/user-roles.slice";

const AssetStatsCard: FunctionComponent = () => {
    const { t } = useTranslation();
    const myAssets = useAppSelector(getMyAssets);
    const assetsLoading = useAppSelector(getIsLoading);
    const unassignedAssets = useAppSelector(getUnassignedAssets);
    const assets = useAppSelector(getAssets);
    const myAssetsCount = myAssets ? myAssets.length : 0;
    const unassignedAssetsCount = unassignedAssets ? unassignedAssets.length : 0;
    const assetsCount = assets ? assets.length : 0;
    const isManager = useAppSelector(getHasEditRole);

    return (
        <Card>
            <CardHeader header={
                <div className="flex items-center">
                    <Subtitle2>{t('dashboard.assetOverview')}</Subtitle2>
                    <InfoLabel size="small" info={
                        <>
                            {t('dashboard.assetOverviewHint')}{" "}
                            <Link target="_blank" href="https://asset.manager">{t('general.learnMore')}</Link>
                        </>
                    } />
                </div>} />

            {assetsLoading &&
                <div className="w-full h-full flex justify-center items-center">
                    <Spinner />
                </div>
            }

            {!assetsLoading &&
                <div className="flex gap-8">
                    <div className="flex flex-col">
                        <Body1Strong>{t('dashboard.myAssets')}:</Body1Strong>
                        {isManager && <Body1Strong>{t('dashboard.unassignedAssets')}:</Body1Strong>}
                        {isManager && <Body1Strong>{t('dashboard.companyAssets')}:</Body1Strong>}
                    </div>
                    <div className="flex flex-col">
                        <Body1>{myAssetsCount}</Body1>
                        {isManager && <Body1>{unassignedAssetsCount}</Body1>}
                        {isManager && <Body1>{assetsCount}</Body1>}
                    </div>
                </div>
            }
        </Card>
    );
};

export default AssetStatsCard;