import { Dialog, DialogSurface, DialogTrigger, ToolbarButton } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import {
    bundleIcon,
    EyeRegular,
    EyeFilled
} from "@fluentui/react-icons";
import ViewAssetDialogBody from "./ViewAssetDialogBody";
import { AssetDialogProps } from "./asset-dialogs.model";

const View = bundleIcon(EyeFilled, EyeRegular);

const ViewAssetToolbarDialog: FunctionComponent<AssetDialogProps> = ({
    assets
}) => {
    return (
        <Dialog>
            <DialogTrigger>
                <ToolbarButton appearance="subtle" icon={<View />} />
            </DialogTrigger>
            <DialogSurface>
                <ViewAssetDialogBody assets={assets} />
            </DialogSurface>
        </Dialog>
    );
}

export default ViewAssetToolbarDialog;