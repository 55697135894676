import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Text, ToolbarButton } from "@fluentui/react-components";
import { FunctionComponent, PropsWithChildren, useContext } from "react";
import {
    bundleIcon,
    DeleteRegular,
    DeleteFilled
} from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { Asset, deleteAssets } from "../assets.slice";
import { useAppDispatch } from "../../../state/hooks";
import { TeamsFxContext } from "../../../components/Context";

const Delete = bundleIcon(DeleteFilled, DeleteRegular);

export interface DeleteAssetsToolbarDialogProps {
    disabled?: boolean;
    assets: Asset[];
}

const DeleteAssetsToolbarDialog: FunctionComponent<PropsWithChildren<DeleteAssetsToolbarDialogProps>> = ({
    children,
    disabled = false,
    assets
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);

    const confirmDeleteAssets = () => {
        dispatch(deleteAssets({ assets, teamsUserCredential: teamsUserCredential! }));
    };

    return (
        <Dialog>
            <DialogTrigger>
                <ToolbarButton disabled={disabled} aria-label="Delete" appearance="subtle" icon={<Delete />}>{children}</ToolbarButton>
            </DialogTrigger>
            <DialogSurface>
                <DialogTitle>{t('assetDialog.deleteTitle')}</DialogTitle>
                <DialogBody>
                    <DialogContent>
                        {assets !== null && assets.length === 1 && <Text>{t('assetDialog.deleteSingleConfirm')}</Text>}
                        {assets !== null && assets.length > 1 && <Text>{t('assetDialog.deleteMultiConfirm', { number: assets.length })}</Text>}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">{t('general.cancel')}</Button>
                        </DialogTrigger>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="primary" onClick={confirmDeleteAssets}>{t('general.delete')}</Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default DeleteAssetsToolbarDialog;