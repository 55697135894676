import React from "react";
import { useContext } from "react";
import { TeamsFxContext } from "../../components/Context";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { Settings } from "./Settings";
import { getSettings, postSettingsState, SettingsState } from "./settings.slice";
import { UserRole } from "../user-roles/user-role.model";
import { getHasEditRole, getUserRoles, postUserRolesState } from "../user-roles/user-roles.slice";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, ToolbarButton } from "@fluentui/react-components";
import { SettingsRegular } from "@fluentui/react-icons";
import { useTranslation } from 'react-i18next';

export function SettingsDialog({ showLabel }: { showLabel: boolean }) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const settingsState = useAppSelector(getSettings);
    const [settingsDraft, setSettingsDraft] = React.useState<SettingsState>(settingsState);
    const userRolesState = useAppSelector(getUserRoles)
    const [userRolesDraft, setUserRolesDraft] = React.useState<UserRole[]>([]);
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);

    // validate settings todo
    const validSettingsDraft = true;

    return (<Dialog
        open={open}
        onOpenChange={(event, data) => {
            // reset draft state on dialog cancel or open
            setUserRolesDraft(userRolesState.userRoles);
            setSettingsDraft(settingsState);
            setOpen(data.open)
        }}>
        <DialogTrigger>
            <ToolbarButton
                icon={<SettingsRegular />}
                title="Settings"
            >{showLabel ? t('settings.title') : ''}</ToolbarButton>
        </DialogTrigger>
        <DialogSurface>
            <DialogBody>
                <DialogTitle>{t('settings.title')}</DialogTitle>
                <DialogContent>
                    <Settings
                        settingsDraft={settingsDraft}
                        setSettingsDraft={setSettingsDraft}
                        userRolesDraft={userRolesDraft}
                        setUserRolesDraft={setUserRolesDraft}
                    />
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="secondary">{t('general.cancel')}</Button>
                    </DialogTrigger>

                    <Button appearance="primary" disabled={!validSettingsDraft} onClick={() => {
                        dispatch(postSettingsState({ state: settingsDraft, teamsUserCredential: teamsUserCredential! }));
                        dispatch(postUserRolesState({ userRoles: userRolesDraft, teamsUserCredential: teamsUserCredential! }));
                        setOpen(false);
                    }}>{t('general.save')}</Button>
                </DialogActions>

            </DialogBody>
        </DialogSurface>

    </Dialog >)
}