import { Subtitle2 } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import CreateTicketDialog from "../tickets/ticket-dialogs/CreateTicketDialog";
import { getHasEditRole } from "../user-roles/user-roles.slice";
import { useAppSelector } from "../../state/hooks";

const NoTicketsHint: FunctionComponent = () => {
    const { t } = useTranslation();
    const isManager = useAppSelector(getHasEditRole);

    return (
        <div className="flex flex-col justify-center items-center m-8 gap-8">
            <Subtitle2>{isManager ? t('ticketTables.noTickets') : t('ticketTables.noMyTickets')}</Subtitle2>
            {!isManager && <CreateTicketDialog />}
        </div>
    );
}

export default NoTicketsHint;