import { Toolbar, ToolbarButton } from "@fluentui/react-components";
import { Feedback } from "../feedback/Feedback";
import { useContext, useEffect, useState } from "react";
import { Info } from "../info/Info";
import { InfoRegular, PersonFeedbackRegular } from '@fluentui/react-icons';
import { SettingsDialog } from "../settings/SettingsDialog";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { getHasEditRole, loadUserRolesState } from "../user-roles/user-roles.slice";
import { TeamsFxContext } from "../../components/Context";

export function MainMenu() {
    const canEdit = useAppSelector(getHasEditRole);
    const { teamsUserCredential } = useContext(TeamsFxContext);
    const dispatch = useAppDispatch();

    // only required if user is admin
    const [userRolesStateLoaded, setUserRolesStateLoaded] = useState(false);
    useEffect(() => {
        if (canEdit && teamsUserCredential && !userRolesStateLoaded) {
            dispatch(loadUserRolesState(teamsUserCredential!));
            setUserRolesStateLoaded(true);
        }
    }, [canEdit, teamsUserCredential, userRolesStateLoaded]);

    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [infoOpen, setInfoOpen] = useState(false);

    return (
        <>
            <Toolbar>
                <SettingsDialog showLabel={true} />
                <ToolbarButton icon={<PersonFeedbackRegular />} onClick={() => setFeedbackOpen(true)}>Feedback</ToolbarButton>
                <ToolbarButton icon={<InfoRegular />} onClick={() => setInfoOpen(true)}>Info</ToolbarButton>
            </Toolbar>
            <Feedback open={feedbackOpen} setOpen={setFeedbackOpen} />
            <Info open={infoOpen} setOpen={setInfoOpen} />
        </>
    );
}
