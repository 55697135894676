import { Card, CardHeader, InfoLabel, Link, Subtitle2 } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../state/hooks";
import { getHasEditRole } from "../user-roles/user-roles.slice";
import CreateAssetDialog from "../assets/asset-dialogs/CreateAssetDialog";
import CreateTicketDialog from "../tickets/ticket-dialogs/CreateTicketDialog";

const ActionsCard: FunctionComponent = () => {
    const { t } = useTranslation();
    const isManager = useAppSelector(getHasEditRole);

    return (
        <Card>
            <CardHeader header={
                <div className="flex items-center">
                    <Subtitle2>{t('dashboard.actions')}</Subtitle2>
                    <InfoLabel size="small" info={
                        <>
                            {t('dashboard.actionsHint')}{" "}
                            <Link target="_blank" href="https://asset.manager">{t('general.learnMore')}</Link>
                        </>
                    } />
                </div>} />
            {isManager && <CreateAssetDialog />}
            <CreateTicketDialog />
        </Card>
    );
};

export default ActionsCard;