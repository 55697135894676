import { TeamsUserCredential } from "@microsoft/teamsfx";
import { SettingsState } from "./settings.slice";
import { ApiService } from "../../services/api-service";

export function postSettingsToAPI(state: SettingsState, teamsUserCredential: TeamsUserCredential) {
  return ApiService.postRequest(teamsUserCredential, "settings", {
    // todo: add settings fields
  });
}

export function getSettingsFromAPI(teamsUserCredential: TeamsUserCredential): Promise<SettingsState> {
  return ApiService.getRequest(teamsUserCredential, "settings");
}

export interface SettingsTeamsFx {
  state: SettingsState;
  teamsUserCredential: TeamsUserCredential;
}
