import './Settings.scss';
import { IDynamicPerson, PeoplePicker, Person } from "@microsoft/mgt-react";
import { useContext, useState } from "react";
import { Roles, UserRole } from "../user-roles/user-role.model";
import { TeamsFxContext } from '../../components/Context';
import { Button, DropdownProps, Tab, TabList } from '@fluentui/react-components';
import { AddRegular, DismissRegular } from '@fluentui/react-icons';
import { SettingsState } from './settings.slice';
import { useTranslation } from 'react-i18next';
import { UserRoleSelect } from './permissions/UserRoleSelect';

export function Settings({ settingsDraft, setSettingsDraft, userRolesDraft, setUserRolesDraft }:
  { settingsDraft: SettingsState, setSettingsDraft: any, userRolesDraft: UserRole[], setUserRolesDraft: any }) {
  const { t, i18n } = useTranslation();
  const [addUserState, setAddUserState] = useState<IDynamicPerson[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [userId, setUserId] = useState('');
  const themeString = useContext(TeamsFxContext).themeString;
  const { teamsUserCredential } = useContext(TeamsFxContext);

  if (!userId) {
    teamsUserCredential?.getUserInfo().then(user => {
      setUserId(user.objectId);
    });
  }

  function submitUser() {
    const newUsers: UserRole[] = [];
    for (const user of addUserState) {

      // check if user was alread added
      if (userRolesDraft.find((u: UserRole) => u.id === user.id)) {
        continue;
      }

      const newUser: UserRole = {
        id: user.id ?? "",
        role: Roles.Admin
      };
      newUsers.push(newUser);
    }
    setUserRolesDraft([...userRolesDraft, ...newUsers]);
    setAddUserState([]);
  }

  function removeUser(user: UserRole) {
    const newUserRoles = userRolesDraft.filter((u: UserRole) => u.id !== user.id);
    setUserRolesDraft(newUserRoles);
  }

  function changeUserRole(userRole: UserRole) {
    const index = userRolesDraft.findIndex((u: UserRole) => u.id === userRole.id);
    setUserRolesDraft([...userRolesDraft.slice(0, index), userRole, ...userRolesDraft.slice(index + 1)]);
  };

  const userRows = userRolesDraft && userRolesDraft.map((user: UserRole, index: any) => {
    return (
      <li key={index} data-userid={user}>
        <div className='user-role'>
          <Person userId={user.id} view='oneline' line1Property="displayName" line2Property="userPrincipalName"></Person>
        </div>
        <UserRoleSelect
          userRole={user}
          disabled={userId === user.id}
          onChange={changeUserRole}
        />

        <Button
          className='delete-role-button'
          appearance='transparent'
          onClick={(e) => removeUser(user)}
          disabled={userId === user.id}
          icon={<DismissRegular />} />
      </li>);
  });

  return (
    <div className={themeString === "default" ? "settings" : "settings dark"}>
      <TabList
        defaultSelectedValue={0}
        onTabSelect={(e, data) => { setActiveTab(data?.value as number ?? 0) }}
      >
        <Tab value={0} content={t('settings.general.title') ?? undefined} />
        <Tab value={1} content={t('settings.permissions.title') ?? undefined} />
      </TabList>

      {activeTab === 0 && <div className='tab-content'>
        <section>
          Settings
        </section>
      </div>}

      {activeTab === 1 && <div className='permissions'>
        <div className='role-people-picker-wrapper '>
          <PeoplePicker
            selectedPeople={addUserState}
            userType='user'
            placeholder={t('settings.permissions.peoplePickerPlaceholder') ?? undefined}
            selectionChanged={(e: any) => setAddUserState(e.detail)} />

          <Button icon={<AddRegular />} appearance="primary" className='add' onClick={submitUser} disabled={addUserState.length === 0}>
            {t('general.add')}
          </Button>
        </div>
        <ul className="user-roles-list">{userRows}</ul>
      </div>}
    </div>
  );
}

