import { FunctionComponent, useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "./Context";
import { useData } from "@microsoft/teamsfx-react";
import { ApiService } from "../services/api-service";
import { scopes } from "../shared/scopes";
import { applyTheme, Providers, ProviderState } from "@microsoft/mgt-react";
import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import { MgtPerson } from "@microsoft/mgt";
import Dashboard from "../features/dashboard/Dashboard";
import { useAppDispatch } from "../state/hooks";
import { loadCurrentUserRoleState } from "../features/user-roles/user-roles.slice";
import { unwrapResult } from "@reduxjs/toolkit";
import { MainMenu } from "../features/main-menu/MainMenu";
import { Button, Subtitle2 } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";

MgtPerson.config.useContactApis = false;

const DashboardTab: FunctionComponent = () => {
  const { t } = useTranslation();
  const [needConsent, setNeedConsent] = useState(false);
  const { themeString, teamsUserCredential } = useContext(TeamsFxContext);
  const dispatch = useAppDispatch();
  const teamsInit = !!teamsUserCredential;

  useEffect(() => {
    applyTheme(themeString === "default" ? "light" : "dark");
  }, [themeString]);

  const { loading, data, error, reload } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }

    const provider = new TeamsFxProvider(teamsUserCredential, scopes);
    Providers.globalProvider = provider;

    if (needConsent) {
      await teamsUserCredential!.login(scopes);
      Providers.globalProvider.setState(ProviderState.SignedIn);
      setNeedConsent(false);
    }
    try {
      const token = await teamsUserCredential.getToken(scopes);
      await ApiService.postRequest(teamsUserCredential, "tenants/init", {});
      const functionRes = await ApiService.getRequest(teamsUserCredential, "getUserProfile");

      // function auth worked
      Providers.globalProvider.setState(ProviderState.SignedIn);
      loadData();
      return functionRes;
    } catch (error: any) {
      console.warn('catch', error);
      setNeedConsent(true);
    }
  });

  async function loadData() {
    // actually load data
    dispatch(loadCurrentUserRoleState(teamsUserCredential!))
      .then(unwrapResult)
      .then(() => {
        // load my assets
        console.log("TODO: load my assets");
      });
  }

  return (
    <div className={themeString === "default" ? "light" : themeString === "dark" ? "dark" : "contrast"}>
      {loading &&
        <div className="w-full h-full flex justify-center items-center">
          <Subtitle2>{t('general.loading')}</Subtitle2>
        </div>}

      {!loading && !data && <div className="login-wrapper">
        <div className="login">
          <img src="./img/color.png" alt="logo" className="logo" />
          {teamsInit &&
            <>
              <p>{t('start.graphPermissions')}</p>
              <Button
                appearance="primary"
                disabled={loading || data}
                onClick={reload}>
                {t('start.authorize')}
              </Button>
            </>
          }

          {!teamsInit &&
            <p><b>The app only works inside Microsoft Teams.</b></p>
          }

          {!!error &&
            <p>
              <pre>{JSON.stringify(error, null, 2)}</pre>
            </p>
          }
        </div>
      </div>
      }

      {!loading && !!data &&
        <>
          <MainMenu />
          <Dashboard />
        </>}
    </div >
  );
}

export default DashboardTab;