import { FunctionComponent, useState } from "react";
import {
    bundleIcon,
    SearchRegular,
    SearchFilled
} from "@fluentui/react-icons";
import {
    Input, InputProps,
    Toolbar, ToolbarDivider, useId
} from "@fluentui/react-components";
import { AssetTableViewToolbarProps } from "./asset-table.model";
import AssetFiltersToolbarMenu from "./AssetFiltersToolbarMenu";
import DeleteAssetsToolbarDialog from "../asset-dialogs/DeleteAssetsToolbarDialog";
import { useTranslation } from "react-i18next";
import EditAssetToolbarDialog from "../asset-dialogs/EditAssetToolbarDialog";
import { AssetTableFilterOptions, getFilters, setFilters } from "../assets.slice";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { getHasEditRole } from "../../user-roles/user-roles.slice";

const Search = bundleIcon(SearchFilled, SearchRegular);

const AssetTableViewToolbar: FunctionComponent<AssetTableViewToolbarProps> = ({
    selection,
    types,
    locations
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const filters = useAppSelector(getFilters);
    const queryInputId = useId('queryInputId');
    const isManager = useAppSelector(getHasEditRole);

    const onQueryChange: InputProps["onChange"] = (ev, data) => {
        const newFilters: AssetTableFilterOptions = {
            ...filters,
            query: data.value
        };

        dispatch(setFilters(newFilters));
    };

    return (
        <div className='px-4 py-4'>
            <Toolbar aria-label="Assets Toolbar">
                <div className={`flex gap-4 ${isManager ? 'mr-4' : ''}`}>
                    <Input id={queryInputId} contentAfter={<Search />} aria-label="Search assets" onChange={onQueryChange} />
                    <AssetFiltersToolbarMenu types={types} locations={locations} />
                </div>

                {isManager &&
                    <div className="flex">
                        <ToolbarDivider />
                        <EditAssetToolbarDialog assets={selection!} disabled={!selection || selection.length < 1}>{t('general.edit')}</EditAssetToolbarDialog>
                        <DeleteAssetsToolbarDialog disabled={!selection || selection.length < 1}
                            assets={selection!}>{t('general.delete')}</DeleteAssetsToolbarDialog>
                    </div>}
            </Toolbar>
        </div>
    );
}

export default AssetTableViewToolbar;