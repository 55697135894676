import { TeamsUserCredential } from "@microsoft/teamsfx";
import { Roles, UserRole } from "./user-role.model";
import { ApiService } from "../../services/api-service";

export function postUserRolessToAPI(userRoles: UserRole[], teamsUserCredential: TeamsUserCredential): Promise<UserRole[]> {
  return ApiService.postRequest(teamsUserCredential!, "userroles", userRoles);
}

export function getUserRolesFromAPI(teamsUserCredential: TeamsUserCredential): Promise<UserRole[]> {
  return ApiService.getRequest(teamsUserCredential!, "userroles");
}

export function getUserRoleFromAPI(teamsUserCredential: TeamsUserCredential, userId: string): Promise<UserRole> {
  return ApiService.getRequest(teamsUserCredential!, `userroles/${userId}`);
}

export interface UserRolesTeamsFx {
  userRoles: UserRole[];
  teamsUserCredential: TeamsUserCredential;
}
