import { TeamsUserCredential } from "@microsoft/teamsfx";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../state/store";
import { getSettingsFromAPI, postSettingsToAPI, SettingsTeamsFx } from "./settings.api";

export type WeekDays = [boolean, boolean, boolean, boolean, boolean, boolean, boolean];

export interface SettingsState {
  isLoading: boolean;
}

export const initialState: SettingsState = {
  isLoading: false,
};

export const loadSettingsState = createAsyncThunk("settings/loadSettingsState", async (teamsUserCredential: TeamsUserCredential) => {
  const stateFromApi = await getSettingsFromAPI(teamsUserCredential);
  const settingsState: SettingsState = {
    isLoading: false,
  };
  return settingsState;
});

export const postSettingsState = createAsyncThunk(
  "settings/updateSettingsState",
  async (data: SettingsTeamsFx) => {
    const stateFromApi = await postSettingsToAPI(data.state, data.teamsUserCredential);
    const settingsState: SettingsState = {
      isLoading: false,
    };
    return settingsState;
  }
);

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, { payload }: PayloadAction<SettingsState>) => {
      return { ...payload };
    },

    isLoading: (state) => {
      state.isLoading = true
    },

    isLoadingFinished: (state) => {
      state.isLoading = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadSettingsState.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loadSettingsState.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loadSettingsState.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postSettingsState.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postSettingsState.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postSettingsState.rejected, (state, action) => {
        state.isLoading = false;
      });
  }
});

export const getSettings = (state: RootState) => state.settings;

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
