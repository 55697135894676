import { TeamsUserCredential } from "@microsoft/teamsfx";
import config from "../config";

export class ApiService {
    static async getRequest(teamsUserCredential: TeamsUserCredential, functionName: string) {
        const accessToken = await teamsUserCredential.getToken("");
        const endpoint = config.apiEndpoint + "/api/" + functionName;
        const response = await fetch(endpoint, {
            headers: {
                authorization: "Bearer " + accessToken?.token || "",
            },
        });
        const data = await response.json();
        return data;
    }

    static async postRequest(teamsUserCredential: TeamsUserCredential, functionName: string, data: any) {
        const accessToken = await teamsUserCredential.getToken("");
        const endpoint = config.apiEndpoint + "/api/" + functionName;
        const response = await fetch(endpoint, {
            method: "POST",
            headers: {
                authorization: "Bearer " + accessToken?.token || "",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        return responseData;
    }

    static async putRequest(teamsUserCredential: TeamsUserCredential, path: string, data: any) {
        const accessToken = await teamsUserCredential.getToken("");
        const endpoint = config.apiEndpoint + "/api/" + path;
        const response = await fetch(endpoint, {
            method: "PUT",
            headers: {
                authorization: "Bearer " + accessToken?.token || "",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        return responseData;
    }

    static async deleteRequest(teamsUserCredential: TeamsUserCredential, path: string) {
        const accessToken = await teamsUserCredential.getToken("");
        const endpoint = config.apiEndpoint + "/api/" + path;
        const response = await fetch(endpoint, {
            method: "DELETE",
            headers: {
                authorization: "Bearer " + accessToken?.token || "",
            },
        });
        return response;
    }
}
