import { TeamsUserCredential } from "@microsoft/teamsfx";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserRoleFromAPI, getUserRolesFromAPI, postUserRolessToAPI, UserRolesTeamsFx } from "./user-roles.api";
import { Roles, UserRole } from "./user-role.model";
import { RootState } from "../../state/store";

export interface UserRolesState {
  userRoles: UserRole[];
  currentUserRole?: Roles;
  currentUserId?: string;
  isLoading: boolean;
}

export const initialState: UserRolesState = {
  userRoles: [],
  currentUserRole: undefined,
  currentUserId: undefined,
  isLoading: false,
};

export const loadUserRolesState = createAsyncThunk("userroles/loadUserRolesState", async (teamsUserCredential: TeamsUserCredential) => {
  return await getUserRolesFromAPI(teamsUserCredential);
});

export const loadCurrentUserRoleState = createAsyncThunk("userroles/loadCurrentUserRoleState", async (teamsUserCredential: TeamsUserCredential) => {
  const userId = (await teamsUserCredential.getUserInfo()).objectId;

  try {
    const userRole: UserRole = await getUserRoleFromAPI(teamsUserCredential, userId);
    return userRole;
  }
  catch (error: any) {
    throw JSON.stringify(error.response);
  }
});

export const postUserRolesState = createAsyncThunk(
  "userroles/updateUserRolesState",
  async (data: UserRolesTeamsFx) => {
    if (!data.userRoles || data.userRoles.length === 0) {
      throw new Error("No user roles to post");
    }
    return await postUserRolessToAPI(data.userRoles, data.teamsUserCredential);
  }
);

const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    isLoading: (state) => {
      state.isLoading = true
    },

    isLoadingFinished: (state) => {
      state.isLoading = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadUserRolesState.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loadUserRolesState.fulfilled, (state, action) => {
        state.userRoles = action.payload;
        state.isLoading = false;
      })
      .addCase(loadUserRolesState.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postUserRolesState.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(postUserRolesState.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userRoles = action.payload;
      })
      .addCase(postUserRolesState.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loadCurrentUserRoleState.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loadCurrentUserRoleState.fulfilled, (state, action) => {
        state.currentUserRole = action.payload.role;
        state.currentUserId = action.payload.id;
        state.isLoading = false;
      })
      .addCase(loadCurrentUserRoleState.rejected, (state, action) => {
        state.isLoading = false;
      });
  }
});

export const getUserRoles = (state: RootState) => state.userRoles;
export const getHasEditRole = (state: RootState) => state.userRoles.currentUserRole === Roles.Admin;
export const getCurrentUserId = (state: RootState) => state.userRoles.currentUserId;

export const { isLoading, isLoadingFinished } =
  userRolesSlice.actions;

export default userRolesSlice.reducer;
