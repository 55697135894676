import { Asset, AssetTableFilterOptions } from "./assets.slice";

export class AssetsService {

    static getTypes(assets: Asset[]): string[] {
        const typeSet = new Set<string>();

        assets.forEach(item => {
            if (item.type) {
                typeSet.add(item.type);
            }
        });

        return Array.from(typeSet);
    }

    static getLocations(assets: Asset[]): string[] {
        const typeSet = new Set<string>();

        assets.forEach(item => {
            if (item.location) {
                typeSet.add(item.location);
            }
        });

        return Array.from(typeSet);
    }

    static filterAssets(assets: Asset[], filters: AssetTableFilterOptions): Asset[] {
        return assets.filter((asset) => {
            if (filters.query && !asset.name.toLowerCase().includes(filters.query.toLowerCase())) {
                return false;
            }

            if (filters.type && asset.type !== filters.type) {
                return false;
            }

            if (filters.location && asset.location !== filters.location) {
                return false;
            }

            if (filters.usedBy && asset.usedBy !== filters.usedBy) {
                return false;
            }

            if (filters.managedBy && asset.managedBy !== filters.managedBy) {
                return false;
            }

            return true;
        });
    }
}