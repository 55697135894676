import { Dialog, DialogSurface, DialogTrigger, ToolbarButton } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import {
    bundleIcon,
    EyeRegular,
    EyeFilled
} from "@fluentui/react-icons";
import { TicketDialogProps } from "./ticket-dialogs.model";
import ViewTicketDialogBody from "./ViewTicketDialogBody";

const View = bundleIcon(EyeFilled, EyeRegular);

const ViewTicketToolbarDialog: FunctionComponent<TicketDialogProps> = ({
    tickets
}) => {
    return (
        <Dialog>
            <DialogTrigger>
                <ToolbarButton appearance="subtle" icon={<View />} />
            </DialogTrigger>
            <DialogSurface>
                <ViewTicketDialogBody tickets={tickets} />
            </DialogSurface>
        </Dialog>
    );
}

export default ViewTicketToolbarDialog;