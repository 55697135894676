import { Button, DialogActions, DialogBody, DialogContent, DialogTitle, DialogTrigger, Field, InfoLabel } from "@fluentui/react-components";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { IDynamicPerson, PeoplePicker } from "@microsoft/mgt-react";
import { AssetDialogProps } from "./asset-dialogs.model";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { TeamsFxContext } from "../../../components/Context";
import { getLocations, getTypes, loadAssets } from "../assets.slice";
import DynamicCombobox from "../../../components/input/DynamicCombobox";

type ValidationState = "error" | "none" | "warning" | "success" | undefined;

interface FormData {
    nameInput: string;
    nameValidationState: ValidationState;
    typeInput: string;
    typeValidationState: ValidationState;
    locationInput: string;
    locationValidationState: ValidationState;
    usedByInput: string | undefined;
    usedByValidationState: ValidationState;
    managedByInput: string | undefined;
    managedByValidationState: ValidationState;
    notesInput: string;
}

const MultiEditAssetDialogBody: FunctionComponent<AssetDialogProps> = ({
    assets
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);
    useEffect(() => {
        if (teamsUserCredential) {
            dispatch(loadAssets(teamsUserCredential!));
        }
    }, [teamsUserCredential]);

    const asset = assets[0];

    const defaultFormData: FormData = {
        nameInput: asset ? asset.name : '',
        nameValidationState: 'none',
        typeInput: asset ? asset.type : '',
        typeValidationState: 'none',
        locationInput: asset ? asset.location : '',
        locationValidationState: 'none',
        usedByInput: asset ? asset.usedBy : '',
        usedByValidationState: 'none',
        managedByInput: asset ? asset.managedBy : '',
        managedByValidationState: 'none',
        notesInput: asset ? asset.notes : ''
    };

    const types: string[] = useAppSelector(getTypes);
    const locations: string[] = useAppSelector(getLocations);
    const [formData, setFormData] = useState<FormData>(defaultFormData);

    const onTypeChange = (value: string | null) => {
        setFormData({
            ...formData,
            typeInput: value ? value : '',
            typeValidationState: value ? 'success' : 'error'
        });
    };

    const onLocationChange = (value: string | null) => {
        setFormData({
            ...formData,
            locationInput: value ? value : '',
            locationValidationState: value ? 'success' : 'error'
        });
    };

    const onUsedByChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }
        setFormData({
            ...formData,
            usedByInput: id,
        });
    };

    const onManagedByChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }
        setFormData({
            ...formData,
            managedByInput: id,
        });
    };

    const validate = (): boolean => {
        let anyInvalid = false;

        if (!formData.typeInput) {
            anyInvalid = true;
        }

        if (!formData.locationInput) {
            anyInvalid = true;
        }

        if (!formData.managedByInput) {
            anyInvalid = true;
        }

        return !anyInvalid;
    };

    const createAsset = async () => {
        
    };

    const canSave = validate();

    return (
        <DialogBody>
            <DialogTitle>{t('assetDialog.editMulti', { number: assets.length })}</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4 py-4">
                    <Field required validationState={formData.typeValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.typeHint')}>
                            {t('assetDialog.type')}
                        </InfoLabel>
                    }>
                        <DynamicCombobox defaultValue={formData.typeInput} values={types} onSelect={onTypeChange} allowFreeform />
                    </Field>

                    <Field required validationState={formData.locationValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.locationHint')}>
                            {t('assetDialog.location')}
                        </InfoLabel>
                    }>
                        <DynamicCombobox defaultValue={formData.locationInput} values={locations} onSelect={onLocationChange} allowFreeform />
                    </Field>

                    <Field validationState={formData.usedByValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.usedByHint')}>
                            {t('assetDialog.usedBy')}
                        </InfoLabel>
                    }>
                        <PeoplePicker
                            defaultSelectedUserIds={formData.usedByInput ? [formData.usedByInput] : []}
                            userType='user'
                            selectionMode="single"
                            selectionChanged={onUsedByChange} />
                    </Field>

                    <Field required validationState={formData.managedByValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.managedByHint')}>
                            {t('assetDialog.managedBy')}
                        </InfoLabel>
                    }>
                        <PeoplePicker
                            defaultSelectedUserIds={formData.managedByInput ? [formData.managedByInput] : []}
                            userType='user'
                            selectionMode="single"
                            selectionChanged={onManagedByChange} />
                    </Field>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">{t('general.close')}</Button>
                </DialogTrigger>
                <DialogTrigger disableButtonEnhancement>
                    <Button disabled={!canSave} appearance="primary" onClick={createAsset}>{t('general.save')}</Button>
                </DialogTrigger>
            </DialogActions>
        </DialogBody>
    );
}

export default MultiEditAssetDialogBody;