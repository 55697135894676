import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Ticket, TicketsState } from "./tickets.model";
import { RootState } from "../../state/store";
import { ApiService } from "../../services/api-service";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { TicketsService } from "./tickets.service";

export const initialState: TicketsState = {
    isLoading: false,
    tickets: [],
    openTickets: []
}

export interface TicketCreatePayload {
    title: string;
    description: string;
    assignedTo: string;
}

export const loadTickets = createAsyncThunk("tickets/loadTickets",
    async (teamsUserCredential: TeamsUserCredential) => {
        const tickets = await ApiService.getRequest(teamsUserCredential, "tickets");
        return tickets;
    }
);
export const postTicket = createAsyncThunk("tickets/postTicket",
    async (data: { newTicket: TicketCreatePayload, teamsUserCredential: TeamsUserCredential }) => {
        const ticket = await ApiService.postRequest(data.teamsUserCredential, "tickets", data.newTicket) as Ticket;
        return ticket;
    }
);

export const putTicket = createAsyncThunk("tickets/putTicket",
    async (data: { ticket: Ticket, teamsUserCredential: TeamsUserCredential }) => {
        const ticket = await ApiService.putRequest(data.teamsUserCredential, "tickets", data.ticket) as Ticket;
        return ticket;
    }
);

export const deleteTicket = createAsyncThunk("tickets/deleteTicket",
    async (data: { ticketId: string, teamsUserCredential: TeamsUserCredential }) => {
        const path = `tickets/${data.ticketId}`;
        await ApiService.deleteRequest(data.teamsUserCredential, path);
        return data.ticketId;
    }
);

const ticketsSlice = createSlice({
    name: "tickets",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadTickets.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(loadTickets.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tickets = action.payload;
            state.openTickets = TicketsService.filterOpenTickets(state.tickets);
        });
        builder.addCase(loadTickets.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(postTicket.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(postTicket.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tickets.push(action.payload);
            state.openTickets.push(action.payload);
        });
        builder.addCase(postTicket.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(putTicket.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(putTicket.fulfilled, (state, action) => {
            state.isLoading = false;
            const index = state.tickets.findIndex((ticket) => ticket.id === action.payload.id);
            state.tickets[index] = action.payload;

            if (!TicketsService.isOpen(action.payload)) {
                state.openTickets = TicketsService.filterOpenTickets(state.tickets);
            }
        });
        builder.addCase(putTicket.rejected, (state) => {
            state.isLoading = false;
        });
        builder.addCase(deleteTicket.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteTicket.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tickets = state.tickets.filter((ticket) => ticket.id !== action.payload);
        });
        builder.addCase(deleteTicket.rejected, (state) => {
            state.isLoading = false;
        });
    }
});

export const { setIsLoading } = ticketsSlice.actions;
export const getIsLoading = (state: RootState) => state.tickets.isLoading;
export const getOpenTickets = (state: RootState) => state.tickets.openTickets;

export default ticketsSlice.reducer;