import './UserRoleSelect.scss';
import { useId, makeStyles, Dropdown, Option, DropdownProps } from "@fluentui/react-components";
import { useTranslation } from 'react-i18next';
import { Roles, UserRole } from "../../user-roles/user-role.model";


interface UserRoleSelectProps {
  userRole: UserRole;
  onChange: (userRole: UserRole) => void;
  disabled?: boolean;
}

export const UserRoleSelect = ({ userRole, onChange, disabled }: UserRoleSelectProps) => {

  const changeUserRole: Partial<DropdownProps>["onOptionSelect"] = (ev, data) => {
    onChange({ ...userRole, role: data!.optionValue! as Roles });
  };

  return (
    <RoleSelect
      selectedOptions={[userRole.role!]}
      disabled={disabled}
      onOptionSelect={changeUserRole} />
  );
}


const useOverrides = makeStyles({
  dropdown: {
    minWidth: "120px",
  }
});


export const RoleSelect = (props: Partial<DropdownProps>) => {
  const styles = useOverrides();
  const comboId = useId("role-select");
  const { t } = useTranslation();

  const items = Object.values(Roles) as string[];

  return (
    <div>
      <Dropdown
        className={`dropclass ` + styles.dropdown}
        id={`${comboId}`}
        {...props}
        value={t('settings.permissions.roles.' + props.selectedOptions![0]?.toLowerCase())}
        listbox={{ className: 'user-role-select-listbox' }}
        appearance="filled-darker">
        {
          items.map((option) => (
            <Option key={option} text={option} value={option}>
              <div className="role-option">
                <span>{t('settings.permissions.roles.' + option.toLowerCase())}</span>
                <span className='description'>{t('settings.permissions.roles.' + option.toLowerCase() + 'Description')}</span>
              </div>
            </Option>
          ))
        }
      </Dropdown>
    </div>
  );
};