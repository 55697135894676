import { FunctionComponent } from "react";
import {
    FilterRegular,
    FilterFilled
} from "@fluentui/react-icons";
import {
    Menu, MenuList, MenuPopover,
    MenuTrigger, ToolbarButton, 
    useId, Button} from "@fluentui/react-components";
import { AssetFilterProps } from "./asset-table.model";
import { useTranslation } from "react-i18next";
import { IDynamicPerson, PeoplePicker } from "@microsoft/mgt-react";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { AssetTableFilterOptions, getFilters, setFilters } from "../assets.slice";
import DynamicCombobox from "../../../components/input/DynamicCombobox";

export interface AssetFiltersToolbarMenuProps extends AssetFilterProps {
    types: string[];
    locations: string[];
}

const AssetFiltersToolbarMenu: FunctionComponent<AssetFiltersToolbarMenuProps> = ({
    types,
    locations
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const usedByInputId = useId('usedByInputId');
    const managedByInputId = useId('managedByInputId');
    const filters = useAppSelector(getFilters);

    const filtersApplied = () => {
        return filters?.type || filters?.location || filters?.usedBy || filters?.managedBy;
    };

    const resetAll = () => {
        dispatch(setFilters({}));
    };

    const onTypeChange = (value: string | null) => {
        const newFilters: AssetTableFilterOptions = {
            ...filters,
            type: value ?? undefined
        };
        dispatch(setFilters(newFilters));
    };

    const onLocationChange = (value: string | null) => {
        const newFilters: AssetTableFilterOptions = {
            ...filters,
            location: value ?? undefined
        };
        dispatch(setFilters(newFilters));
    };

    const onUsedByChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }

        const newFilters: AssetTableFilterOptions = {
            ...filters,
            usedBy: id
        };
        dispatch(setFilters(newFilters));
    };

    const onManagedByChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }

        const newFilters: AssetTableFilterOptions = {
            ...filters,
            managedBy: id
        };
        dispatch(setFilters(newFilters));
    };

    return (
        <Menu>
            <MenuTrigger>
                <ToolbarButton appearance="primary" icon={filtersApplied() ? <FilterFilled /> : <FilterRegular />} />
            </MenuTrigger>

            <MenuPopover>
                <div className="flex justify-end mr-[-8px]">
                    <Button appearance="transparent" onClick={resetAll}>{t('assetTable.filters.resetAll')}</Button>
                </div>
                <MenuList className="m-2">
                    <div className="flex flex-col gap-4">
                        <div>
                            <label>{t('assetTable.columns.type')}</label>
                            <DynamicCombobox values={types} defaultValue={filters?.type} onSelect={onTypeChange} />
                        </div>
                        <div>
                            <label>{t('assetTable.columns.location')}</label>
                            <DynamicCombobox values={locations} defaultValue={filters?.location} onSelect={onLocationChange} />
                        </div>
                        <div>
                            <label>{t('assetTable.columns.usedBy')}</label>
                            <PeoplePicker
                                id={usedByInputId}
                                className="w-full mt-2"
                                defaultSelectedUserIds={filters?.usedBy ? [filters.usedBy] : []}
                                userType='user'
                                selectionMode="single"
                                selectionChanged={onUsedByChange} />
                        </div>
                        <div>
                            <label>{t('assetTable.columns.managedBy')}</label>
                            <PeoplePicker
                                id={managedByInputId}
                                className="w-full mt-2"
                                defaultSelectedUserIds={filters?.managedBy ? [filters.managedBy] : []}
                                userType='user'
                                selectionMode="single"
                                selectionChanged={onManagedByChange} />
                        </div>
                    </div>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};

export default AssetFiltersToolbarMenu;