import { FunctionComponent } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    Button,
} from "@fluentui/react-components";
import {
    bundleIcon,
    AddCircleRegular,
    AddCircleFilled
} from "@fluentui/react-icons";

import EditAssetDialogBody from "./EditAssetDialogBody";
import { useTranslation } from "react-i18next";

const AddCircle = bundleIcon(AddCircleFilled, AddCircleRegular);

const CreateAssetDialog: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Button
                    appearance="primary"
                    icon={<AddCircle />}>{t('assetDialog.createAsset')}</Button>
            </DialogTrigger>
            <DialogSurface>
                <EditAssetDialogBody />
            </DialogSurface>
        </Dialog>
    );
};

export default CreateAssetDialog;