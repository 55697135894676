import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../state/hooks";
import { getHasEditRole } from "../../user-roles/user-roles.slice";
import { Ticket } from "../tickets.model";
import { DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, SelectionItemId, TableCellLayout, TableColumnDefinition, Toolbar, createTableColumn } from "@fluentui/react-components";
import { Person } from "@microsoft/mgt-react";
import NoTicketsHint from "./NoTicketsHint";
import ViewTicketToolbarDialog from "../ticket-dialogs/ViewTicketToolbarDialog";
import { getOpenTickets } from "../tickets.slice";

export interface TicketTableViewProps {
    columns?: string[]
}

const TicketTableView: FunctionComponent<TicketTableViewProps> = ({
    columns = [
        'title',
        'description',
        'createdBy',
        'assignedTo',
        'actions'
    ]
}) => {
    const { t } = useTranslation();
    const isManager = useAppSelector(getHasEditRole);
    const filteredTickets: Ticket[] = useAppSelector(getOpenTickets);

    const updateSelection = (selectionSet: Set<SelectionItemId>) => {
        const selected: Ticket[] = [];

        selectionSet.forEach((id) => {
            const assetIndex = filteredTickets.findIndex((a) => a.id === id);
            if (assetIndex !== -1) {
                selected.push(filteredTickets[assetIndex]);
            }
        });

        // setSelection(selected);
    };

    const renderedColumns: TableColumnDefinition<Ticket>[] = [];

    if (columns.includes('title')) {
        renderedColumns.push(createTableColumn<Ticket>({
            columnId: 'title',
            compare: (a, b) => {
                return a.title.localeCompare(b.title);
            },
            renderHeaderCell: () => {
                return t('ticketTable.columns.title');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.title}
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('description')) {
        renderedColumns.push(createTableColumn<Ticket>({
            columnId: 'description',
            compare: (a, b) => {
                return a.description.localeCompare(b.description);
            },
            renderHeaderCell: () => {
                return t('ticketTable.columns.description');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        {item.description}
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('createdBy')) {
        renderedColumns.push(createTableColumn<Ticket>({
            columnId: 'createdBy',
            compare: (a, b) => {
                return a.createdBy.localeCompare(b.createdBy);
            },
            renderHeaderCell: () => {
                return t('ticketTable.columns.createdBy');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <Person view="oneline" personCardInteraction="hover" userId={item.createdBy} />
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('assignedTo')) {
        renderedColumns.push(createTableColumn<Ticket>({
            columnId: 'assignedTo',
            compare: (a, b) => {
                return a.assignedTo.localeCompare(b.assignedTo);
            },
            renderHeaderCell: () => {
                return t('ticketTable.columns.assignedTo');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <Person view="oneline" personCardInteraction="hover" userId={item.assignedTo} />
                    </TableCellLayout>
                );
            }
        }));
    }

    if (columns.includes('actions')) {
        renderedColumns.push(createTableColumn<Ticket>({
            columnId: 'actions',
            compare: (a, b) => {
                return a.title.localeCompare(b.title);
            },
            renderHeaderCell: () => {
                return t('ticketTable.columns.actions');
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout>
                        <Toolbar>
                            {isManager && <ViewTicketToolbarDialog tickets={[item]} />}
                        </Toolbar>
                    </TableCellLayout>
                );
            }
        }));
    }

    return (
        <div>
            <DataGrid items={filteredTickets}
                columns={renderedColumns}
                sortable
                selectionMode="multiselect"
                subtleSelection
                getRowId={(item) => item.id}
                focusMode="cell"
                onSelectionChange={(_e, d) => updateSelection(d.selectedItems)}>
                <DataGridHeader>
                    <DataGridRow selectionCell={{
                        checkboxIndicator: { 'aria-label': 'Select all rows' }
                    }}>
                        {({ renderHeaderCell }) => (<DataGridHeaderCell>
                            {renderHeaderCell()}
                        </DataGridHeaderCell>)}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<Ticket>>
                    {({ item, rowId }) => (
                        <DataGridRow<Ticket> key={rowId} selectionCell={{
                            checkboxIndicator: { 'aria-label': 'Select row' }
                        }}>
                            {({ renderCell }) => (
                                <DataGridCell>
                                    {renderCell(item)}
                                </DataGridCell>)}
                        </DataGridRow>
                    )}
                </DataGridBody>
            </DataGrid>

            {filteredTickets.length === 0 && <NoTicketsHint />}
        </div>
    );
}

export default TicketTableView;