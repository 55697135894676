import './Info.scss'
import { Dialog, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, DialogTrigger, Button } from "@fluentui/react-components";
import packageJson from "../../../package.json";
import { useContext, useState } from "react";
import { TeamsFxContext } from "../../components/Context";
import { UserInfo } from "@microsoft/teamsfx";
import { t } from "i18next";

export function Info({ open, setOpen }: { open: boolean, setOpen: (isOpen: boolean) => void }) {

  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  if (!userInfo) {
    teamsUserCredential?.getUserInfo().then((info) => {
      setUserInfo(info);
    });
  }

  const content = <div className='info'>
    {t('info.currentVersion')}: {packageJson.version}

    <h2>{t('info.yourInfo')}</h2>
    <p>{t('info.note')}</p>
    <ul>
      <li>User Id: {userInfo?.objectId}</li>
      <li>Tenant Id: {userInfo?.tenantId}</li>
    </ul>
    <h2>Changelog</h2>

    <h3>Version 1.x</h3>
    <ul>
      <li>Init</li>
    </ul>

  </div>

  return (
    <Dialog
      open={open}
      onOpenChange={(event: any, data: any) => setOpen(data.open)}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('info.title')}</DialogTitle>
          <DialogContent>
            {content}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">{t('general.close')}</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
}