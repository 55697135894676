import { FunctionComponent } from "react";

const Privacy: FunctionComponent = () => {
  return (
    <div>
      <h1>Privacy Statement</h1>
    </div>
  );
}

export default Privacy;
