import { Title1, InfoLabel, Link } from "@fluentui/react-components";
import { FunctionComponent } from "react";
import WidgetContainer from "./WidgetContainer";
import ActionsCard from "./ActionsCard";
import AssetStatsCard from "./AssetStatsCard";
import TicketStatsCard from "./TicketStatsCard";
import AssetTables from "./AssetTables";
import { useTranslation } from "react-i18next";
import TicketTables from "./TicketTables";

export const Dashboard: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="p-6">
                <div className="pb-8 flex items-center">
                    <Title1>{t('dashboard.dashboard')}</Title1>
                    <InfoLabel info={
                        <>
                            {t('dashboard.dashboardHint')}{" "}
                            <Link target="_blank" href="https://asset.manager/">{t('general.learnMore')}</Link>
                        </>
                    } />
                </div>

                <WidgetContainer>
                    <AssetStatsCard />
                    <TicketStatsCard />
                    <ActionsCard />
                </WidgetContainer>
            </div>

            <div>
                <TicketTables />
                <AssetTables />
            </div>
        </div>
    );
}

export default Dashboard;