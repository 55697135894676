import './Feedback.scss'
import { useContext, useState } from "react";
import { TeamsFxContext } from "../../components/Context";
import {
    Dialog, DialogTrigger, Button, DialogSurface, DialogTitle, DialogContent, DialogActions, DialogBody,
    Checkbox, CheckboxProps, Input, useId, Label, Textarea, makeStyles,
    Field
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';
import { ApiService } from '../../services/api-service';

const isMobile = window.innerWidth < 600;
const useStyles = makeStyles({
    textarea: {
        height: isMobile ? '80px' : '200px'
    }
});

export function Feedback({ open, setOpen }: { open: boolean, setOpen: (isOpen: boolean) => void }) {
    const { t } = useTranslation();
    const styles = useStyles();

    const [user, setUser] = useState<any>(null);
    const [feedback, setFeedback] = useState("");
    const [isNewsletter, setIsNewsletter] = useState<CheckboxProps['checked']>(false);
    const [loading, setLoading] = useState(false);

    const nameInputId = useId('name-input');
    const emailInputId = useId('email-input');
    const { teamsUserCredential } = useContext(TeamsFxContext);

    if (!user) {
        teamsUserCredential!.getUserInfo().then(user => {
            setUser(user);
        });
    }

    async function sendFeedback() {
        setLoading(true);
        await ApiService.postRequest(teamsUserCredential!, "feedback", { message: feedback, newsletter: isNewsletter });
        setLoading(false);
        setOpen(false);
    }

    const feedbackForm = <>{user && <div className="feedback-form">
        <Field label={t('feedback.name')}>
            <Input id={nameInputId} value={user.displayName} disabled />
        </Field>
        <Field label={t('feedback.email')}>
            <Input id={emailInputId} value={user.preferredUserName} disabled />
        </Field>
        <Textarea
            placeholder={t('feedback.feedback-placeholder') || "Your feedback"}
            textarea={{ className: styles.textarea }}
            onChange={(e: any, d: any) => setFeedback(d?.value ?? "")}
        />
        <div>
            <Checkbox checked={isNewsletter} onChange={(ev, data) => setIsNewsletter(data.checked)} label={t('feedback.subscribe')} />
        </div>
    </div>}</>;

    return (<>
        <Dialog
            open={open}
            onOpenChange={(event, data) => setOpen(data.open)}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>{t('feedback.title')}</DialogTitle>
                    <DialogContent>
                        {feedbackForm}
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">{t('general.cancel')}</Button>
                        </DialogTrigger>
                        <Button appearance="primary" disabled={loading || feedback === ""} onClick={sendFeedback}>{t('general.send')}</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    </>);
}