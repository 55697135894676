import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import assetsReducer from '../features/assets/assets.slice';
import ticketsReducer from '../features/tickets/tickets.slice';
import userRolesReducer from '../features/user-roles/user-roles.slice';
import settingsReducer from '../features/settings/settings.slice';

export const store = configureStore({
    reducer: {
        assets: assetsReducer,
        tickets: ticketsReducer,
        userRoles: userRolesReducer,
        settings: settingsReducer
    },
});

export const getRootState = (state: RootState) => state;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;

