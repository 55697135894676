import { Button, Caption1, DialogActions, DialogBody, DialogContent, DialogTitle, DialogTrigger, Field, InfoLabel, Input, InputProps, Textarea, TextareaProps } from "@fluentui/react-components";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { IDynamicPerson, PeoplePicker } from "@microsoft/mgt-react";
import { useTranslation } from "react-i18next";
import { Asset, AssetCreatePayload, getLocations, getTypes, postAsset } from "../assets.slice";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { TeamsFxContext } from "../../../components/Context";
import DynamicCombobox from "../../../components/input/DynamicCombobox";

type ValidationState = "error" | "none" | "warning" | "success" | undefined;

interface FormData {
    nameInput: string;
    nameValidationState: ValidationState;
    typeInput: string;
    typeValidationState: ValidationState;
    locationInput: string;
    locationValidationState: ValidationState;
    usedByInput: string | undefined;
    usedByValidationState: ValidationState;
    managedByInput: string | undefined;
    managedByValidationState: ValidationState;
    notesInput: string;
}

export interface EditAssetDialogBodyProps {
    asset?: Asset;
}

const EditAssetDialogBody: FunctionComponent<EditAssetDialogBodyProps> = ({
    asset = null
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { teamsUserCredential } = useContext(TeamsFxContext);

    const defaultFormData: FormData = {
        nameInput: asset ? asset.name : '',
        nameValidationState: 'none',
        typeInput: asset ? asset.type : '',
        typeValidationState: 'none',
        locationInput: asset ? asset.location : '',
        locationValidationState: 'none',
        usedByInput: asset ? asset.usedBy : '',
        usedByValidationState: 'none',
        managedByInput: asset ? asset.managedBy : '',
        managedByValidationState: 'none',
        notesInput: asset ? asset.notes : ''
    };

    const types: string[] = useAppSelector(getTypes);
    const locations: string[] = useAppSelector(getLocations);
    const [formData, setFormData] = useState<FormData>(defaultFormData);

    const onNameChange: InputProps["onChange"] = (ev, data) => {
        setFormData({
            ...formData,
            nameInput: data.value,
            nameValidationState: !data.value ? 'error' : 'success'
        });
    };

    const onTypeChange = (value: string | null) => {
        setFormData({
            ...formData,
            typeInput: value ? value : '',
            typeValidationState: value ? 'success' : 'error'
        });
    };

    const onLocationChange = (value: string | null) => {
        setFormData({
            ...formData,
            locationInput: value ? value : '',
            locationValidationState: value ? 'success' : 'error'
        });
    };

    const onUsedByChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }
        setFormData({
            ...formData,
            usedByInput: id,
        });
    };

    const onManagedByChange = (data: CustomEvent<IDynamicPerson[]>) => {
        let id = undefined;
        if (data.detail.length > 0) {
            id = data.detail[0].id;
        }
        setFormData({
            ...formData,
            managedByInput: id,
        });
    };

    const onNotesChange: TextareaProps["onChange"] = (ev, data) => {
        setFormData({
            ...formData,
            notesInput: data.value
        });
    };

    const validate = (): boolean => {
        let anyInvalid = false;

        if (!formData.nameInput) {
            anyInvalid = true;
        }

        if (!formData.typeInput) {
            anyInvalid = true;
        }

        return !anyInvalid;
    };

    const createAsset = () => {
        const newAsset: AssetCreatePayload = {
            name: formData.nameInput,
            type: formData.typeInput,
            location: formData.locationInput,
            usedBy: formData.usedByInput,
            managedBy: formData.managedByInput,
            notes: formData.notesInput
        };
        dispatch(postAsset({ newAsset, teamsUserCredential: teamsUserCredential! }));
    };

    const canSave = validate();

    return (
        <DialogBody>
            <DialogTitle>{asset ? t('assetDialog.editAsset') : t('assetDialog.createAsset')}</DialogTitle>
            <DialogContent>
                <div className="flex flex-col gap-4 py-4">
                    {!asset && <Caption1>{t('assetDialog.createAssetInstruction')}</Caption1>}

                    <Field required validationState={formData.nameValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.nameHint')}>
                            {t('assetDialog.name')}
                        </InfoLabel>
                    }>
                        <Input defaultValue={formData.nameInput} onChange={onNameChange} />
                    </Field>

                    <Field required validationState={formData.typeValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.typeHint')}>
                            {t('assetDialog.type')}
                        </InfoLabel>
                    }>
                        <DynamicCombobox defaultValue={formData.typeInput} values={types} onSelect={onTypeChange} allowFreeform />
                    </Field>

                    <Field validationState={formData.locationValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.locationHint')}>
                            {t('assetDialog.location')}
                        </InfoLabel>
                    }>
                        <DynamicCombobox defaultValue={formData.locationInput} values={locations} onSelect={onLocationChange} allowFreeform />
                    </Field>

                    <Field validationState={formData.usedByValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.usedByHint')}>
                            {t('assetDialog.usedBy')}
                        </InfoLabel>
                    }>
                        <PeoplePicker
                            defaultSelectedUserIds={formData.usedByInput ? [formData.usedByInput] : []}
                            userType='user'
                            selectionMode="single"
                            selectionChanged={onUsedByChange} />
                    </Field>

                    <Field validationState={formData.managedByValidationState} label={
                        <InfoLabel className="inline-flex" info={t('assetDialog.managedByHint')}>
                            {t('assetDialog.managedBy')}
                        </InfoLabel>
                    }>
                        <PeoplePicker
                            defaultSelectedUserIds={formData.managedByInput ? [formData.managedByInput] : []}
                            userType='user'
                            selectionMode="single"
                            selectionChanged={onManagedByChange} />
                    </Field>

                    <Field label={t('assetDialog.notes')} hint={t('assetDialog.notesHint')}>
                        <Textarea defaultValue={formData.notesInput} onChange={onNotesChange} />
                    </Field>
                </div>
            </DialogContent>
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">{t('general.close')}</Button>
                </DialogTrigger>
                <DialogTrigger disableButtonEnhancement>
                    <Button disabled={!canSave} appearance="primary" onClick={createAsset}>{t('general.save')}</Button>
                </DialogTrigger>
            </DialogActions>
        </DialogBody>
    );
}

export default EditAssetDialogBody;