import { FunctionComponent } from "react";
import {
    Body1,
    Body1Strong,
    Card,
    CardHeader,
    InfoLabel,
    Link,
    Spinner,
    Subtitle2,
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../state/hooks";
import { getIsLoading, getOpenTickets } from "../tickets/tickets.slice";
import { getCurrentUserId, getHasEditRole } from "../user-roles/user-roles.slice";
import { TicketsService } from "../tickets/tickets.service";

const TicketStatsCard: FunctionComponent = () => {
    const { t } = useTranslation();
    const ticketsLoading = useAppSelector(getIsLoading);
    const openTickets = useAppSelector(getOpenTickets);
    const currentUserId = useAppSelector(getCurrentUserId);

    const ticketsCreatedByMeCount = openTickets ? TicketsService.filterTicketsCreatedBy(currentUserId!, openTickets).length : 0;
    const openTicketsCount = openTickets ? openTickets.length : 0;
    const isManager = useAppSelector(getHasEditRole);

    return (
        <Card>
            <CardHeader header={
                <div className="flex items-center">
                    <Subtitle2>{t('dashboard.ticketOverview')}</Subtitle2>
                    <InfoLabel size="small" info={
                        <>
                            {t('dashboard.ticketOverviewHint')}{" "}
                            <Link target="_blank" href="https://asset.manager">{t('general.learnMore')}</Link>
                        </>
                    } />
                </div>} />

            {ticketsLoading &&
                <div className="w-full h-full flex justify-center items-center">
                    <Spinner />
                </div>
            }

            {!ticketsLoading &&
                <div className="flex gap-8">
                    <div className="flex flex-col">
                        <Body1Strong>{t('dashboard.myOpenTickets')}:</Body1Strong>
                        {isManager && <Body1Strong>{t('dashboard.companywideTickets')}:</Body1Strong>}
                    </div>
                    <div className="flex flex-col">
                        <Body1>{ticketsCreatedByMeCount}</Body1>
                        {isManager && <Body1>{openTicketsCount}</Body1>}
                    </div>
                </div>
            }
        </Card>
    );
};

export default TicketStatsCard;