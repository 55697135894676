import "./App.scss";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import DashboardTab from "./DashboardTab";
import { TeamsFxContext } from "./Context";
import config from "../config";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "../state/store";
import "../globals.css";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
  });

  return (
    <I18nextProvider i18n={i18n}>
      <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
        <FluentProvider
          theme={
            themeString === "dark"
              ? teamsDarkTheme
              : themeString === "contrast"
                ? teamsHighContrastTheme
                : {
                  ...teamsLightTheme,
                  colorNeutralBackground3: "#eeeeee",
                }
          }
          style={{ background: tokens.colorNeutralBackground3 }}
        >
          <ReduxProvider store={store}>
            <Router>
              {loading ? (
                <Spinner style={{ margin: 100 }} />
              ) : (
                <Routes>
                  <Route path="/dashboard" element={<DashboardTab />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/termsofuse" element={<TermsOfUse />} />
                  <Route path="*" element={<Navigate to={"/dashboard"} />}></Route>
                </Routes>
              )}
            </Router>
          </ReduxProvider>
        </FluentProvider>
      </TeamsFxContext.Provider>
    </I18nextProvider>
  );
}
